.checkout-checkout,
.extension-payment-pp_express-expressConfirm {
  #deliverydatetime {
    .col-sm-6 {
      padding: 0;
      margin: 0 0 15px;
    }
  }

  #payment-existing,
  #shipping-existing {
    @include flexbox();
    @include flex-wrap(wrap);
    @include flex-basis(50%);

    .radio {
      @include flex(1, 1, 50%);
      min-width: 200px;
    }
  }

  #ppplus iframe {
    width: 100% !important;
  }
}

@media (min-width: 768px) {
  .checkout-checkout,
  .extension-payment-pp_express-expressConfirm {
    #deliverydatetime {
      > .col-sm-6 {
        padding-right: 7px;
      }
    }
    #irs {
      > .col-sm-6 {
        padding-left: 7px;
      }
    }
  }
}