.simple-banner {
  overflow: hidden;
  z-index: -1;
  border-bottom: 1px solid #ffffff;

  .item {
    position: relative;
    height: 300px;
  }
}

.simple-banner-image {
  z-index: -1;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 0;
}

@media (max-width: 767px) {
  .simple-banner .item {
    height: 150px;
  }
}