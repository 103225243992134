/* product */

.product {
  max-width: 1024px;
  margin: 0 auto;

  h1,
  h2.price{
    margin-top: 0;
  }

  .price-old {
    color: $mack-button-color;
    text-decoration: line-through;
  }

  .thumbnails {
    .thumbnail {
      border: none !important;

      &:active,
      &:focus,
      &:hover {
        border: none !important;
      }
    }
  }

  .stock {
    font-weight: bold;
  }
}

#product {
  text-align: center;

  .add-to-cart-wrapper {
    margin-top: 15px;

    .control-label {
      width: 100%;
      text-align: left;
      font-size: $mack-font-size-base;
    }

    // Quantity Input
    .number-spinner {
      width: 20%;
      // Hide HTML5 Up and Down arrows.

      .input-group-btn-vertical {
        > .btn {
          padding: 11px 15px;
        }

         > .btn:first-child {
          border-top-right-radius: $mack-form-border-radius;
        }

        > .btn:last-child {
          margin-top: -2px;
          border-bottom-right-radius: $mack-form-border-radius;
        }
      }
    }

    .btn.btn-add-to-card {
      width: 65%;
      display: inline-block;
      vertical-align: top;
      float: right;
      margin: 0;
    }
  }

  .product-meta-info {
    margin-top: 15px;
  }

  .product-configuration {
    text-align: left;
  }
}

#content .product-tags {
  list-style: none;
  a {
    border: 1px solid $mack-button-color;
    border-radius: 2px;
    color: #fff !important;
    padding: 2px 4px;
    margin: 2px;
    display: inline-block;
    text-decoration: none;
    font-family: $mack-font-highlight;
    font-size: $mack-font-size-base !important;
    background: #000;
  }

  #text {
    display: none;
  }
}

#product-thumbs {
  padding-left: 0;
}

#product {
  padding-right: 0;
}

.product-info, .product-related {
  .headline-extra:after {
    margin-bottom: 20px;
  }
}

.product-info {
  border-top: 1px solid $mack-button-grey;

  .col-sm-12{
    padding-left: 0;
    padding-right: 0;
  }
}

.product-related {
  margin-top: 40px;
  padding-top: 40px;
  border-top: 1px solid $mack-button-grey;
}

.product-attribute {
  background: $mack-button-grey;
  border-radius: $mack-frame-border-radius-extra;
  border: 1px solid $mack-button-grey;
  margin: 10px 0;

  &:first-of-type {
    margin-top: 30px;
  }

  h4 {
    margin: 0;
    padding: 10px;
  }

  div {
    padding: 10px 20px;
    background: #fff;
  }

  .text-muted {
    margin-bottom: 10px;
  }

}

.product-tags {
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px solid $mack-button-grey;

  h4 {
    display: block;
    padding: 7px 0;
    margin: 0;
  }

  a{
    text-decoration: underline;
    background: $mack-button-grey;
    border-radius: $mack-frame-border-radius-extra;
    padding: 5px 8px;
    font-weight: normal !important;
    color: $mack-link-color !important;
    font-size: $mack-font-size-x-small !important;
    border: none;

    &:hover {
      text-decoration: none;
      color: $mack-headline-color !important;
    }
  }
}


@media (max-width: 767px) {

  #product-thumbs {
    padding-left: 0;
    padding-right: 0;
  }

  #product {
    padding-left: 0;
    padding-right: 0;
  }
}