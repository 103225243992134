/* search */
#search {
  //margin: 4px 0;
  margin: 0;
  margin-top: 10px;

  .header-search &{
    float: right;
    padding-bottom: 10px;
  }

  .nav-search & {
    margin: 7px 0;
    position: absolute;
    width: 25%;
    right: 0;
    top: 0;
  }

  input[name="search"] {
    @include box-shadow(none);
    border-radius: 0;

    padding-right: 30px;
    height: 40px;
    line-height: 40px !important;
    border-color: white;
    background-color: white;
    font-size: $mack-font-size-base;
    color: $mack-button-color;

    border-bottom-left-radius: $mack-form-border-radius;
    border-top-left-radius: $mack-form-border-radius;
  }

  .input-group-btn {
    //border: 1px solid white;
    //border-left: none;
    background-color: white;
    //background-image: $mack-menu-button;
   // background-size: cover;
    //background-repeat: no-repeat;

    border-bottom-right-radius: $mack-form-border-radius;
    border-top-right-radius: $mack-form-border-radius;

    //&:hover {
    //  border-color: $gray-light;
    //}
  }

  .btn {
    @include box-shadow(none);
    //border-radius: 0;
    color: $mack-anthrazit;
    height: 38px;
    display: block;
    border: none !important;
    background: none;
    width: 40px;


    //&:hover {
    //  background: rgba(255, 255, 255,0.3);
    //}
  }

  .btn-default {
    text-shadow: none;
  }
}

.common-home {
  .header-search,
  #search {
    display: none;
  }
}

.row.list-configuration {
  padding-bottom: 20px;
  padding-top: 5px;
  border-top: 3px solid $mack-button-grey;
}

.row.search-criteria {
  border-bottom: 1px solid $mack-button-grey;
}

#refine-search-container,
#sort-container {

  .text-right {
    padding-right: 15px;
    margin-bottom: 10px;
  }

  &:after{
    clear: both;
  }
}

#sort-container {
  div {
    vertical-align: middle;
  }

  label {
    display: inline-block;
    width: 100%;
  }

  .select-parent{
    width: 100%;
    display: inline-block;
  }
}

#refine-search-container {
  input[type="checkbox"] {
    position: static;
    margin-left: 0;
  }

  span {
    line-height: 1.5em;
    padding: 0;
    margin: 0 10px 0 26px;
    display: block;
  }
}

.row.list-configuration, .row.search-criteria {

  .btn-link:hover {
    color: $mack-highlight;
    text-decoration: none;
  }

  .list-group, .list-group-item {
    margin: 0;
  }

  .list-group {
    padding: 0;
  }

  .col-sm-6 {
    padding-left: 10px;
    padding-right: 10px;
  }

  h4.list-group-item {
    margin-top: 0;
    padding-bottom: 3px;
  }

  .list-group-item {
    border: none;
    padding: 0 10px;
  }

  select.form-control {
    font-size: 0.9em;
    width: 100%;
  }

  label {
    line-height: 1.5em;
    padding: 0;
  }

  input[type="text"].form-control {
    font-size: $mack-font-size-base;
  }

  #filter-group-allergene-pre-filter {
    border-bottom: 1px solid $mack-button-grey;
  }

  .filter-list {
    @include flexbox();
    @include flex-wrap(wrap);
    @include flex-basis(100%);
    padding-bottom: 10px;
    padding-top: 10px;
    margin-bottom: 10px;

    .checkbox {
      @include flex(0, 1, 25%);
      margin: 0;

      span {
        line-height: 1.5em;
        padding: 0;
        margin: 0 10px 0 5px;
      }
    }

    .radio {
      margin: 0 10px 0 0;

      label {
        line-height: 1.5em;
        padding: 0;
        margin: 0 10px 0 5px;
      }
    }

    .radio,
    .checkbox {
      input[type="radio"], input[type="checkbox"] {
        position: static;
        margin-left: 0;
        float: left;
      }
    }
  }

  .fa-sort, .fa-filter {
    width: 11px;
    color: $mack-highlight;
    margin-right: 8px;
  }
}



@media (max-width: 767px) {
  #search {
    padding-top: 5px;
    margin-right: 15px;
    margin-top: 0;
    box-shadow: 0 1px 0 rgba(62, 62, 62, 0.5);

  }

  .row.list-configuration, .row.search-criteria {
    .filter-list {
      .checkbox {
        @include flex(1, 1, 45%);
      }
    }

    .radio {
      width: 100%;
    }
  }

  #refine-search-container,
  #sort-container {
    .row div {
      margin-bottom: 10px;
    }

    label {
      margin: 0;
      height: inherit;
    }

    .select-parent {
      width: 100%;
      display: inline-block;
      margin: 0;
    }

  }
}

@media (max-width: 520px) {

  .row.list-configuration, .row.search-criteria {
    .filter-list {
      .checkbox {
        @include flex(1, 1, 100%);
      }
    }
  }
}