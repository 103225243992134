/* buttons */
.buttons {
  margin: 1em 0;
}
.btn {
  padding: $button-padding;
  line-height: 20px;
  font-size: $mack-font-size-small;
  border: 1px solid $mack-grey-light;
  border-radius: $mack-button-radius;
  font-weight: normal;
}

*:focus {
  outline: none !important;
}

.btn-xs {
  font-size: 9px;
}
.btn-sm {
  font-size: 10.2px;
}
.btn-lg {
  padding: 10px 16px;
  font-size: 15px;
}
.btn-group {
  >.btn,
  >.dropdown-menu,
  >.popover {
    font-size: $mack-font-size-x-small;
  }

  >.btn-xs {
    font-size: 9px;
  }

  >.btn-sm {
    font-size: 10.2px;
  }

  >.btn-lg {
    font-size: 15px;
  }
}

.btn-default, #content a.btn-default {
  @include box-shadow($mack-box-shadow-default);
  @include transition($mack-transition-default);
  color: $mack-button-color;
  text-decoration: none !important;
  background-color: $mack-button-grey;

  &:hover {
    @include box-shadow(none);
    @include transition($mack-transition-default);
    color: $mack-button-color;
  }
}

.input-group-btn {
  .btn {
    @include box-shadow(none);
    border: 0;
    border-radius: $mack-frame-border-radius;

    &:hover {
      border: 0;
    }
  }

  >.btn {
    @include transition(none);
    border: 0;

    &:hover,
    &:active,
    &:focus {
      border: 0;
    }
  }
}

.btn-primary {
  @include transition($mack-transition-default);
  @include box-shadow($mack-box-shadow-default);
  color: #fff !important;
  text-shadow: none;
  text-decoration: none !important;
  background: $mack-button-background no-repeat;
  background-size: cover;
  font-family: $mack-font-base;
  border: 1px solid #fff;
  border-radius: $mack-button-primary-radius;
  opacity: 1;

  &:hover,
  &:active,
  &:active:hover,
  &.active,
  &:focus,
  &.disabled,
  &[disabled] {
    @include transition($mack-transition-default);
    @include box-shadow(none);
    background: $mack-button-background no-repeat;
    background-size: cover;
    border: 1px solid #fff;
    opacity: 0.8;
    outline: none;
  }
}

.btn-warning {
  color: #fff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
  background-color: #faa732;
  border-color: #f89406 #f89406 #ad6704;

  &:hover,
  &:active,
  &.active,
  &.disabled,
  &[disabled] {
    opacity: .7;
  }
}

.btn-danger {
  color: #fff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
  background-color: $mack-orange;
  border-color: #bd362f #bd362f #802420;

  &:focus {
    color: #fff;
  }

  &:hover,
  &:active,
  &.active,
  &.disabled,
  &[disabled] {
   opacity: .7;
  }
}

.btn-success {
  color: #fff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
  background-color: #5bb75b;
  border-color: #51a351 #51a351 #387038;

  &:hover,
  &:active,
  &.active,
  &.disabled,
  &[disabled] {
    @include box-shadow(inset 0 1000px 0 rgba(0, 0, 0, 0.1));
    color: #fff;
  }
}

.btn-info {
  color: #fff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
  background-color: #df5c39;
  border-color: #dc512c #dc512c #a2371a;

 &:hover,
 &:active,
 &.active,
 &.disabled,
 &[disabled] {
   @include box-shadow(inset 0 1000px 0 rgba(0, 0, 0, 0.1));
   color: #fff;
   background-color: #df5c39;
   border-color: #dc512c #dc512c #a2371a;
  }
}

.btn-link {
  border-color: rgba(0, 0, 0, 0);
  cursor: pointer;
  color: $mack-button-color;
  border-radius: 0;

  &:hover,
  &:active,
  &[disabled] {
    @include box-shadow(none);
    background-color: rgba(0, 0, 0, 0);
    background-image: none;
    color: $mack-button-color;
  }
}

.btn-inverse {
  color: #fff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
  background-color: #363636;
  border-color: $gray-darker $gray-darker #000;

  &:hover,
  &:active,
  &.active,
  &.disabled,
  &[disabled] {
    background-color: $gray-darker;
  }
}


/* Missing focus and border color to overwrite bootstrap */
.btn-info:hover, .btn-info:active, .btn-info.active, .btn-info.disabled, .btn-info[disabled] {
  background-image: none;
  background-color: #df5c39;
}

.btn.btn-add-to-card, .button-checkout {
  @include box-shadow($mack-box-shadow-default);
  @include transition($mack-transition-default);
  background: $mack-highlight !important;
  color: #fff !important;
  font-family: $mack-font-base;
  font-size: $mack-font-size-base;
  box-sizing: border-box;
  border: 1px solid #fff;
  border-radius: $mack-button-primary-radius;
  margin-bottom: 10px;
  text-shadow: none;
  opacity: 1;

  &:hover {
    @include box-shadow(none);
    @include transition($mack-transition-default);
    background: $mack-highlight !important;
    opacity: 0.8;
  }
}

.cart-delete, .cart-refresh {
  margin: 0;
  line-height: 20px;

  padding: 6px 5px;
  display: inline-block;
}

button.cart-delete {
  background: #A94442 !important;

  &:hover {
    background: #A94442 !important;
    border: 1px solid #fff;
  }
}
.back-to-top, .back-to-top:active, .back-to-top:visited {
  @include box-shadow($mack-box-shadow-default);
  font-family: Fontawesome;
  position: fixed;
  z-index:10;
  bottom:20px;
  right:20px;
  color: $mack-button-color;
  font-size: 2rem;
  background: $mack-button-grey;
  border-radius: 40px;
  border: 1px solid $mack-grey-light;
  width: 40px;
  height: 40px;
  line-height: 35px;
  text-align: center;
  text-decoration: none;
  z-index: 1000;

  &:hover,
  &:focus {
    @include box-shadow(none);
    @include transition($mack-transition-default);
    background: $gray-lighter;
    color: $mack-base-color;
    text-decoration: none;
  }
}

#collapse-checkout-confirm .buttons {
  @extend .row;
  .btn {
    @extend
      .btn-lg,
      .button-checkout;
    width: 100%;
  }

  > div {
    @extend
      .col-sm-4,
      .col-sm-offset-8,
      .col-xs-12;
    padding: 0;
    float: none !important;
  }
}

.pp-express-button img {
  max-height: 40px;
}