body .cc-theme-edgeless.cc-window {
  font-size: $mack-font-size-small;

  .cc-message {
    margin: 1em;
  }
}


#tarteaucitronRoot {
  div, span {
    font-size: $mack-font-size-small !important;

  }

  button {  font-family: "Quattrocento Sans", sans-serif !important; }

  #tarteaucitronAlertSmall {
    left: 0;
    right: auto;
  }

  .tarteaucitronAlertBigBottom{
    width: auto;
    padding: 1em;
  }

  .tarteaucitronMainLine {
    border-width: 5px !important;
  }
}