/* alert */
.alert {
  padding: 8px $mack-font-size-base;
}

.alert-success {
  position: fixed;
  z-index: 500;
  background: none;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  padding: 0;
  margin: 0;
  border: none !important;
  border-radius: 0 !important;

  div {
    @include box-shadow($mack-box-shadow-default);
    position: fixed;
    z-index: 500;
    top: 50%;
    left: 50%;
    width: 450px;
    height: auto;
    text-align: center;
    margin-top: -50px;
    margin-left: -225px;
    border: 2px solid #fff;
    background: $mack-button-background;
    box-sizing: border-box;
    border-radius: $mack-alert-border-radius;
    padding: 40px 20px;
    color: #fff;

    a {
      color: $mack-highlight !important;
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }
  }

  .close {
    font-size: $mack-font-size-large;
    position: absolute;
    top: 5px;
    right: 10px;
    color: #fff;
    opacity: 1;
    font-weight: normal;

    &:hover {
      opacity: .7;
    }
  }

}

.alert-warning, .alert-danger {
  color: $mack-orange;
  background-color: $mack-orange-light;
  border-color: $mack-orange;
  position: relative;

  .close {
    position: absolute;
    top: 5px;
    right: 10px;
  }

  hr {
    border-top-color: $mack-orange;
  }
  .alert-link {
    color: $mack-orange;
  }
}

.text-danger, .text-warning {
  color: $mack-orange;
}

@media (max-width: 500px){

  .alert-success {
    div {
      left: 5%;
      width: 90%;
      margin-left: 0;
      padding: 20px !important;
    }
  }
}