.bootstrap-datetimepicker-widget td.day.disabled,
.bootstrap-datetimepicker-widget td.day.disabled:hover {
  color: $mack-grey-light;
}

.bootstrap-datetimepicker-widget td.day.active,
.bootstrap-datetimepicker-widget td.day.active:hover {
  @include box-shadow($mack-box-shadow-default);
  @include transition($mack-transition-default);
  background-color: $mack-highlight;
  color: #fff;
  border: 1px solid #fff;
  border-bottom-left-radius: $mack-frame-border-radius-extra;
  border-bottom-right-radius: $mack-frame-border-radius-extra;
}

.bootstrap-datetimepicker-widget td.day.today::before {
  border-bottom-color: $mack-highlight;
}

#dateclick .btn {
  padding: 7px 10px;
}