/* footer */
footer {
  margin-top: 30px;
  padding: 20px 0 15px;
  background-color: $mack-footer-background;
  //background: $mack-background-board-black-stripe no-repeat;
  background: $mack-anthrazit;
  //background-size: cover;
  border-top: 1px solid #ddd;
  color: $mack-button-grey;
  min-height: 200px;

  a {
    color: $mack-button-grey;

    &:hover,
    &:active,
    &:focus {
      color: $mack-highlight;
      text-decoration: none;
    }

    &.active {
      text-decoration: underline;
    }
  }

  ul {
    text-align: center;
    li {
      padding: 5px 10px !important;
    }
  }

  .additional-footer {
    font-size: $mack-font-size-small;
    margin: 50px 0 0 0;

    .fa {
      font-size: 250%;
      vertical-align: middle;
      margin-right: 10px;
    }
  }
}

/**
 STICKY FOOTER
 */
/**
 * sticky footer and stretched site content from: https://github.com/philipwalton/solved-by-flexbox/blob/master/assets/css/components/site.css
 * 1. Avoid the IE 10-11 `min-height` bug.
 * 2. Set `flex-shrink` to `0` to prevent some browsers from
 *    letting these items shrink to smaller than their content's default
 *    minimum size. See http://bit.ly/1Mn35US for details.
 * 3. Use `%` instead of `vh` since `vh` is buggy in older mobile Safari.
 */

.site {
  @include flexbox;
  min-height: 100vh;
  @include flex-direction(column);

  header, footer {
    @include flex(none); /* 2 */
  }

  .stretch-site-content {
    @include flex(1); /* 2 */
  }
}