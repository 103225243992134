
.locations {
  color: $mack-headline-color;
}

.location-sidenav {
  background-color: $mack-button-grey;
}

@media (max-width: 620px) {
  .location-container-overall {
    .location-container {
      .store {
        border-top: 1px solid $mack-button-grey;
      }
    }
  }
}

@media (max-width: 520px) {
  .location-container-overall {
    .location-container {
      &:before{
        background: $mack-button-grey;
      }
    }
  }
}