/*
 FONTS
*/
@font-face {
  font-family: "Cabin Sketch";
  src: url('../font/Cabin_Sketch/CabinSketch-Regular.ttf') format('truetype');
}

@font-face {
  font-family: "Quattrocento Sans";
  src: url('../font/Quattrocento_Sans/QuattrocentoSans-Regular.ttf') format('truetype');
}
/*
@import url(https://fonts.googleapis.com/css?family=Quattrocento+Sans);
@import url(https://fonts.googleapis.com/css?family=Cabin+Sketch);
*/
$mack-font-base: 'Quattrocento Sans', sans-serif;
$mack-font-highlight: 'Cabin Sketch', cursive;
$mack-font-size-base: 16px;
$mack-font-size-small: 14px;
$mack-font-size-x-small: 12px;
$mack-font-size-medium: 27px;
$mack-font-size-large: 32px;

/*
 SIZES
*/
$mack-max-width: 1024px;

/*
 COLORS
*/

$mack-highlight: #d2aa6d;
$mack-anthrazit: #333;

$mack-board-black: #333;
$mack-footer-background: #333;
$mack-headline-color: #444;
$mack-button-color: #444;
$mack-link-color: #888;
$mack-button-grey: #e7e7e7;
$mack-base-color: #666;
$mack-grey-light: #ccc;

$mack-orange: #DD5533;
$mack-orange-light: #ffe1db;
$mack-orange-org: #F6721A;

/*
 BOOTSTRAP
*/
$gray-base:              #000 !default;
$gray-darker:            lighten($gray-base, 13.5%) !default; // #222
$gray-dark:              lighten($gray-base, 20%) !default;   // #333
$gray:                   lighten($gray-base, 33.5%) !default; // #555
$gray-light:             lighten($gray-base, 46.7%) !default; // #777
$gray-lighter:           lighten($gray-base, 93.5%) !default; // #eee

/*
 BACKGROUNDS
*/
$mack-background-board-black: url('../image/board-black-1024.jpg');
$mack-background-board-black-dark: url('../image/board-black-dark-1024.jpg');
$mack-background-board-mobile: url('../image/board-black-767.jpg');
$mack-background-board-mobile-dark: url('../image/board-black-dark-767.jpg');
$mack-background-board-black-stripe: url('../image/board-black-stripe.jpg');
$mack-background-board-black-divider: url('../image/board-black-divider.jpg');
$mack-background-board-black-divider-dark: url('../image/board-black-divider-dark.png');
$mack-background-menu-board: url('../image/menu-board.jpg');
$mack-button-background: url('../image/board-black-400.jpg');
$mack-price-background: url('../image/price.jpg');
$mack-background-coffee: url('../image/coffee.jpg');
$mack-menu-button: url('../image/board-menu-button.jpg');
$mack-underline: url('../image/underline.png');
$mack-underline2: url('../image/underline2.png');

/*
 SIZES
*/
$mack-header-height: 60px;
$mack-header-height-small: 50px;
$mack-logo-height: 50px;
$mack-logo-height-small: 35px;
$button-padding: 6px 10px;
$button-padding-2: 9px 14px;

/*
 BORDERS
*/
$mack-frame-border-radius: 2px;
$mack-form-border-radius: 2px;
$mack-frame-border-radius-extra: 4px;
$mack-alert-border-radius: 4px;
$mack-button-primary-radius: 4px;
$mack-button-radius: 4px;

/*
 TRANSITIONS
*/
$mack-transition-default: all .1s ease;

/*
 SHADOWS
*/

$mack-box-shadow-default: 2px 2px 5px -3px #000;