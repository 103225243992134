.pagination {

  li.active {
    span, a {
      background-color: $mack-base-color;
      border-color: $mack-base-color;

      &:focus,
      &:hover {
        background-color: $mack-base-color;
        border-color: $mack-base-color;
      }
    }
  }
}