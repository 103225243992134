label {
  margin-bottom: 0;
  font-size: $mack-font-size-base;
}

.form-control {
  @include appearance-none;
  @include box-shadow(none !important);
  font-size: $mack-font-size-base;
  font-family: $mack-font-base;
  border-radius: $mack-form-border-radius;
  background-color: $mack-button-grey;
  color: $mack-button-color;
  border: 1px solid;
  border-color: $mack-button-grey;


  .login-returning-customer & {
    background: #fff;
    border: 1px solid $gray-lighter;
  }

  &:focus {
    @include box-shadow(none);
    border: 1px solid;
    border-color: $gray;
  }

  .has-error & {
    @include box-shadow(none);
    border-color: $mack-orange;

    &:focus {
      @include box-shadow(none);
    }
  }
}

/* placeholder */
::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: lighten($mack-base-color, 30%) !important;
}
::-moz-placeholder { /* Firefox 19+ */
  color: lighten($mack-base-color, 30%) !important;
  opacity: 1;
}
:-ms-input-placeholder { /* IE 10+ */
  color: lighten($mack-base-color, 30%) !important;
}
:-moz-placeholder { /* Firefox 18- */
  color: lighten($mack-base-color, 30%) !important;
}

.input-group input, .input-group select, .input-group .dropdown-menu, .input-group .popover {
  font-size: $mack-font-size-base;
}
.input-group .input-group-addon {
  font-size: $mack-font-size-base;
  height: 30px;
}
#account .form-group:not(.required) .form-control:before {
  content: '* ';
  color: $mack-orange;
  font-weight: bold;
}


.input-group .form-control[name^=quantity] {
  min-width: 50px;
}

.has-error {
  .checkbox, .checkbox-inline, .control-label, .help-block, .radio, .radio-inline {
    color: $mack-orange;
  }
}
.has-error.checkbox, .has-error.checkbox-inline, .has-error.radio, .has-error.radio-inline {
  label {
    color: $mack-orange;
  }
}

/* mack form styles */

.select-parent {
  background: $mack-button-grey;
  position: relative;
  border-radius: $mack-form-border-radius;
  z-index:0;
  overflow: hidden;

  &:before {
    content: "\f107";
    font-family: FontAwesome;
    position: absolute;
    right: 0;
    top: 0;
    z-index: -1;
    background-color: $mack-anthrazit;
    background-image: $mack-menu-button;
    color: #fff;
    font-size: $mack-font-size-base;
    line-height: 25px;
    padding: 6px 5px;
    display: inline-block;
  }

  select.form-control {
    @include box-shadow(none);
    @include appearance-none;
    z-index: 1;
    color: $mack-button-color;
    background: none;
    border-radius: $mack-form-border-radius;
    border: none !important;

    &:focus {
      border: 1px solid $gray !important;
      padding-left: 11px;
    }
  }
}

.form-control {
  @include box-shadow(none);
  border-radius: $mack-form-border-radius;
  padding-right: 30px;
  border-color: $mack-button-grey;
  font-size: $mack-font-size-base;
  color: #000;
  line-height: 1.5rem !important;
}

.quantity {
  display: inline-block;
  float: left;
  margin-right: 20px;
}
.cart-quantity {
  margin-right: 10px;
}


// Quantity Input
.number-spinner {
  display: inline-flex;

  /* Hide HTML5 Up and Down arrows. */
  input[type="number"]::-webkit-outer-spin-button, input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type="number"] {
    -moz-appearance: textfield;
  }

  #input-quantity {
    text-align: center;
    margin-bottom: 12px;
    padding-right: 0;
    width: 100%;
    display: inline-block;
    height: 42px;
  }

  .input-group-btn-vertical {
    position: relative;
    white-space: nowrap;
    width: 2%;
    vertical-align: middle;
    display: table-cell;

    > .btn {
      @include box-shadow(none);
      display: block;
      float: none;
      width: 100%;
      max-width: 100%;
      padding: 10.5px 15px;
      position: relative;
      border: none;
      border-left: 1px solid #fff;
      border-radius: 0;
    }

    i{
      position: absolute;
      top: 2.5px;
      left: 11px;
      color: $mack-anthrazit;
    }
  }

}

.form-horizontal .radio,
.form-horizontal .checkbox,
.radio,
.checkbox {
  @extend
    .pdt-1,
    .pdb-1;

  label {
    display: inline;
    width: 100%;

    div {
      display: inline-block;
    }
  }

  input {
    @extend .mgr-1;
    position: relative;
    vertical-align: top;
  }
}

.well {
  @include box-shadow(none);
  background: $mack-button-grey;
}