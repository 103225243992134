.account-account h2 {
  margin-top: 80px;
}

.account-login {
  .col-sm-6:first-of-type {
    padding-left: 0;
  }

  .col-sm-6:last-of-type {
    padding-right: 0;
  }
}

.list-group.square, .list-group.row {

  @include flexbox();
  @include flex-direction('row');
  @include justify-content(center);
  width: 100%;

  .list-group-item {
    display: inherit;
    max-width: 50%;
    text-decoration: none !important;
    margin-left: 5px;
    margin-right: 5px;
    padding: 15px 5px;

    &:hover {
      background: $mack-button-grey;
    }

    &:first-of-type {
      margin-left: 0;
    }

    &:last-of-type {
      margin-right: 0;
    }

    div {
      display: block;
      height: 80px;
      text-align: center;
      width: 100%;

      &.fa {
        font-size: 300%;
      }

      span {
        @include dont-break-out;
        font-family: $mack-font-base;
        font-size: $mack-font-size-base;
        width: 100%;
        display: block;
        padding-top: 10px;
      }
    }

    &:hover div span {
      text-decoration: none;
    }
  }
}

.account-order.site {
  .panel-heading {
    background: $mack-button-grey;
    cursor-pointer: none;
  }
}

@media (max-width: 767px) {

  .account-account h2 {
    margin-top: 10px;
  }

  .account-login {
    .col-sm-6 {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
  }

  .list-group.square{
    @include flex-wrap(wrap);
    .list-group-item {
      width: 48%;
      margin: 5px auto !important;
      height: auto;
    }
  }
}