/* cart
*
* Dieses Stylesheet ist veraltet, Änderungen in _checkout-cart.scss einfügen!
*
*/

.img-shopping-cart {
  height: 40px;
  width: auto;
}

#cart {
  margin-bottom: 10px;

  > .btn {
    font-size: $mack-font-size-x-small;
    line-height: 18px;
    color: #FFF;
  }

  &.open {
    > .btn {
      background-image: none;
      background-color: #fff;
      border: 1px solid #E6E6E6;
      color: $mack-base-color;
      @include box-shadow(none);
      text-shadow: none;
    }
    > .btn:hover {
      color: $mack-button-color;
    }
  }
  .dropdown-menu {
    background: $gray-lighter;
    z-index: 1001;
    min-width: 100%;

    table {
      margin-bottom: 10px;
    }

    li > div {
      min-width: 427px;
      padding: 0 10px;
    }

    li p {
      margin: 20px 0;
    }
  }

  .table > tbody > tr > td, .table > tbody > tr > th, .table > tfoot > tr > td, .table > tfoot > tr > th, .table > thead > tr > td, .table > thead > tr > th {
    vertical-align: middle;
  }

  td .big {
    font-size: 1.5rem;
  }

  table.prices {
    > tbody > tr > td {
      font-weight: normal;
    }

    > tbody > tr.sum > td {
      font-weight: bold;
      font-size: 2rem;
    }
  }
}

@media (max-width: 478px) {
  #cart .dropdown-menu {
    width: 100%;
  }
}

@media (max-width: 478px) {
  #cart .dropdown-menu li > div {
    min-width: 100%;
  }
}
