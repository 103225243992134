/* structure */
.nav-tabs {
  margin-bottom: 15px;
}

.list-centered {
  float: none;
  display: table;
  margin: 0 auto;

  a {
    text-align: center;
  }
}

/* PADDINGS */

.pd-0 {
  padding: 0 !important;
}

.pdr-1 {
  padding-right: $mack-font-size-base/2;
}

.pdl-1 {
  padding-left: $mack-font-size-base/2;
}

.pdb-1 {
  padding-bottom: $mack-font-size-base/2;
}

.pdt-1 {
  padding-top: $mack-font-size-base/2;
}

.pdr-2 {
  padding-right: $mack-font-size-base/3;
}

.pdl-2 {
  padding-left: $mack-font-size-base/3;
}

/* MARGINS */
.mg-0 {
  margin: 0 !important;
}

.mgt-1 {
  margin-top: $mack-font-size-base/2;
}

.mgb-1 {
  margin-bottom: $mack-font-size-base/2;
}

.mgl-1 {
  margin-left: $mack-font-size-base/2;
}

.mgr-1 {
  margin-right: $mack-font-size-base/2;
}

/*
  BOOTSTRAP OVERWRITES
*/
.list-group {
  a.list-group-item {
    border-radius: $mack-frame-border-radius;
    display: table;
    width: 100%;
    line-height: $mack-font-size-base * 1.5;
    padding: 8px 12px;

    span,
    .fa {
      display: table-cell;
      width: 100%;
    }

    .fa {
      width: $mack-font-size-base * 0.5;
      font-size: $mack-font-size-base * 0.5;
      height:  $mack-font-size-base * 0.5;
    }
  }
}

span.hidden-xs, span.hidden-sm, span.hidden-md, span.hidden-lg {
  display: inline;
}
