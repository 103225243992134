.table {
  border-radius: $mack-frame-border-radius;

  // overwrite bootstrap directive
  >tbody,
  >tfoot,
  >thead {
    tr > td,
    tr > th {
      vertical-align: middle;
      white-space: unset !important;

      &.bottom {
        vertical-align: bottom;
      }
    }
  }

  td .big {
    font-size: 1.5rem;
  }

  &.table-top {
    >tbody,
    >tfoot,
    >thead {
      tr > td,
      tr > th {
        vertical-align: top;
      }
    }
  }
}