/* carousel */
.carousel-caption {
  color: #fff;
  text-shadow: 0 1px 0 #000;
}
.carousel-control .icon-prev:before {
  content: '\f053';
  font-family: FontAwesome;
}
.carousel-control .icon-next:before {
  content: '\f054';
  font-family: FontAwesome;
}

/* slider */
.slider {
  text-align: center;
  overflow: hidden;
  overflow-x: auto;
  clear: both;
  white-space: nowrap;
  margin-bottom: 20px;
  -webkit-overflow-scrolling: touch;

  &.thumbnails:not(ul) {
    margin-left: auto;

    .slider-item:not(:first-of-type) {
      margin-left: 20px;
    }
  }

  .slider-item {
    display: inline-block;

    .thumbnail {
      display: inline-block;
      margin-bottom: 0;
    }
  }
}