/* product list */
$grid-basis: 15em;

.products {
  @include flexbox();

  @include justify-content(flex-start);

  &:not(.product-slider) {
    @include flex-wrap(wrap);
  }

  &.product-slider {
    overflow-x: scroll;
    overflow-y: hidden;
    height: auto;
    width: 100%;
  }
}


.product-thumb {
  height: 100%;
  margin-bottom: $mack-font-size-base;
  overflow: visible;

  @include flex(1, 1, auto);
  @include flexbox();
  @include flex-direction(column);
  @include justify-content(space-between);
  @include align-items(stretch);

  .image {
    text-align: center;

    a {
      display: block;

      &:hover {
        opacity: 0.8;
      }
    }

    img {
      margin-left: auto;
      margin-right: auto;
    }
  }

  .caption {
    padding: 0;
    text-align: center;

    &:after {
      content: "";
      clear: both;
      height: 0;
      display: block;
    }
  }

  h4 {
    text-align: center;
    font-size: 1em;
    margin: 10px;
    @include dont-break-out();
    border-bottom: 1px solid rgba(0, 0, 0, .1);

    a{
      color: $mack-headline-color !important;
      text-decoration: none !important;
      display: inline-block;
      padding: 0 0 .5em;

      &:hover{
        text-decoration: none;
        color: $mack-link-color !important;
      }
    }
  }

  .rating {
    padding-bottom: 10px;
  }

  .price {
    color: #fff;
    font-size: $mack-font-size-base;
    float: none;
    padding: 0;
  }

  .price-new {
    font-weight: 600;
  }

  .price-old {
    color: #999;
    text-decoration: line-through;
    margin-right: 5px;
  }

  .price-tax {
    color: #999;
    font-size: $mack-font-size-x-small;
    display: block;
  }

  .btn-add-to-card {
    float: none;
    width: 50%;
    margin: 0 auto 20px;
  }
}

.product-grid {
  padding: 5px;
  @include flex(0);
  float: left;
  width: 10em;

  .product-thumb {
    .image {
      float: none;
    }
  }

}

.product-list .product-thumb .caption {
  margin-left: 230px;
}

.rating .fa-stack {
  font-size: 8px;
}
.rating .fa-star-o {
  color: #999;
  font-size: 15px;
}
.rating .fa-star {
  color: #FC0;
  font-size: 15px;
}
.rating .fa-star + .fa-star-o {
  color: #E69500;
}
h2.price {
  margin: 0;
}

.price-tag {
  display: inline-block;
  color: $mack-headline-color;
  font-size: 120%;
  border-radius: $mack-frame-border-radius-extra;
  width: auto;
  text-align: center;
}

.thumbnails {
  overflow: auto;
  clear: both;
  list-style: none;
  padding: 0;
  margin: 0;
  margin-left: -20px;

  > li {
    margin-left: 20px;
  }

  > img {
    width: 100%;
  }

  .image-additional {
    float: left;
    margin-left: 20px;
    max-width: 78px;

    a {
      margin-bottom: 20px;
      padding: 5px;
      display: block;
      border: 1px solid #ddd;
    }
  }
}


/* fixed colum left + content + right*/
@media (min-width: 768px) {
  #column-left {
    .product-layout .col-md-3 {
      width: 100%;
    }

    + #content .product-layout .col-md-3 {
      width: 50%;
    }

    + #content + #column-right .product-layout .col-md-3 {
      width: 100%;
    }
  }

  #content + #column-right .product-layout .col-md-3 {
    width: 100%;
  }
}

/* fixed product layouts used in left and right columns */
#column-left .product-layout,
#column-right .product-layout {
  width: 100%;
}

@media (min-width: 767px) {
  .product-list .product-thumb .image {
    float: left;
    padding: 0 15px;
  }
}

@media (max-width: 1200px) {
  .product-grid .product-thumb .caption {
    padding: 0 10px;
  }
}

/* Smartphones (portrait and landscape) ----------- */
@media only screen and (max-width : 320px){
  .products {
    &:not(.product-slider) {
      @include justify-content(space-around);
    }
  }
}


/* Smartphones (portrait and landscape) ----------- */
@media only screen and (min-width : 320px) and (max-width : 768px) {
  .product-grid {
    width: 50%;
  }
}

/* tablets (portrait and landscape) ----------- */
@media only screen and (min-width : 768px) and (max-width : 1024px) {
  .product-grid {
    width: 33%;
  }
}

/* Desktops and laptops ----------- */
@media only screen and (min-width : 1024px) {
  .product-grid {
    width: 25%;
  }
}

@media (max-width: 767px) {
  .product-list .product-thumb .caption {
    min-height: 0;
    margin-left: 0;
    padding: 0 10px;
  }
  .product-grid .product-thumb .caption {
    min-height: 0;
  }
}