/* header */
header {
  background-color: $mack-anthrazit;
  position: fixed;
  top: 0;
  width: 100%;
  min-height: $mack-header-height;
  z-index: 101 !important;
  border-bottom: 1px solid $mack-button-grey;
  box-sizing: content-box;
}

#sticky-header-wrapper {
  // initial height
  height: 50px;
}

/* top */
#top {
  padding: 0;
  font-size: $mack-font-size-base*1.25;
}
#top .btn-link, #top-links li, #top-links a {
  color: $mack-button-grey;
  text-shadow: none;
  text-decoration: none;

  .user {
    color: $mack-anthrazit;
  }
}

#top .btn-link:hover, #top-links a:hover, #top-links a.active {
  text-decoration: underline;
}

#top-links .dropdown-menu a {
  text-shadow: none;

  &:hover {
    color: #FFF;
  }
}
#top .btn-link strong {
  font-size: $mack-font-size-small;
  line-height: $mack-font-size-small;
}
#top-links {
  width: 100%;

  ul {
    position: relative;
    margin-bottom: 0;
    @include flexbox();
    @include flex-direction(row);
    @include justify-content(flex-end);
    line-height: $mack-header-height;
    text-align: center;

    li#logo {
      @include flex(1, 1, auto);

      @include flexbox();
      @include flex-direction(column);
      @include justify-content(center);
      float: left;

      a {
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        background-position-x: left;
        height: 80%;
        width: 100%;
        display: inline-block;
      }

    }

    li {
      @include flex(0, 0, auto);
      position: relative;
      min-width: $mack-header-height-small;

      > a {
        font-size: $mack-font-size-base;
        padding: 10px;
        background-color: transparent;
        font-weight: normal;
        text-decoration: none;

        &.active {
          text-decoration: underline;
        }
      }

      > ul {
        position: absolute;
        z-index: 3;
        width: 200px;
        left: -46px;
        top: 50px;
        list-style: none;
        display: none;
        background: #fff;
        border-radius: $mack-form-border-radius;
        border: 1px solid $mack-button-grey;
        padding: 15px 5px;
        box-sizing: border-box;

        li {
          padding: 0;
          line-height: 100%;

          a {
            padding: 5px 15px;
          }

          span {
            display: inline;
          }

          span.user {
            display: inline-block;
            font-size: $mack-font-size-x-small;
            padding: 5px 0 10px 0;
            width: 100%;
            line-height: 110%;
          }
        }
      }

      &:hover > ul {
        display: block;
      }
    }

    .cart-link {
      position: relative;

      .badge {
        background-color: $mack-highlight;
        position: absolute;
        top: 17px;
        margin-top: -10px;
        right: -3px;
        font-size: $mack-font-size-x-small;
        line-height: 21px;
        min-width: 20px !important;
        padding: 0 5px;
        height: 20px;
      }
    }
  }

  a + a {
    margin-left: 15px;
  }
}



#top-links {
  ul {
    li.menu-item-shop {
      margin-bottom: -5px;
      min-height: $mack-header-height - 5px;

      a {
        @include box-shadow($mack-box-shadow-default);
        @include transition($mack-transition-default);
        background: $mack-highlight;
        color: #fff;
        border: 1px solid #fff;
        border-top: none;
        border-bottom-left-radius: $mack-button-primary-radius;
        border-bottom-right-radius: $mack-button-primary-radius;

        &:hover {
          @include transition($mack-transition-default);
          @include box-shadow(none);
          text-decoration: underline;
          background: darken($mack-highlight, 5%) !important;
        }
        &.active {
          text-decoration: underline;
        }
      }
    }
  }
}
@media (max-width: 767px) {

  header{
    min-height: 0;
    height: $mack-header-height-small;
    max-height: $mack-header-height-small;
  }

  #top-links {
    ul{
      line-height: inherit;
      max-height: $mack-header-height-small;

      li {
        line-height: inherit;

        a {
          padding: 0 10px;
          line-height: 50px;
          display: block;
          height: 100%;

          &.active {
            text-decoration: none;
          }
        }
      }

      .cart-link {
        .badge {
          top: 3px;
          margin-top: 0;
        }
      }
    }

  }

  #top .btn-link:hover, #top-links a:hover, #top-links a.active {
    text-decoration: none;
  }
}

@media (min-width: 768px) {
  #top-links ul li.menu-item-shop  a {
    padding-top: 35px;
    padding-bottom: 10px;
  }

  #top-links li.menu-item-information {
    display: none;
  }
}

@media (max-width: 375px) {
  #top-links li.menu-item-information {
    display: none;
  }
}