body {
  font-family: $mack-font-base;
  font-size: $mack-font-size-base;
  font-weight: 400;
  color: $mack-base-color;
  line-height: 20px;
  width: 100%;
}
h1, h2, h3, h4, h5, h6 {
  color: $mack-headline-color;
  font-weight: bold;
}
/* Override the bootstrap defaults */
h1 {
  font-size: $mack-font-size-large;
}

h2 {
  font-size: $mack-font-size-medium;
}

h3 {
  font-size: 21px;
}
h4 {
  font-size: 15px;
}
h5 {
  font-size: 12px;
}
h6 {
  font-size: 10.2px;
}
/* content */
#content {
  position: relative;
}

div.mfp-bg {
  height: 100% !important;
}

div.mfp-gallery {
  top: 0 !important;
}

#content a:not(.btn) {
  color: $mack-headline-color;
  text-decoration: underline;
  vertical-align: middle;
  cursor: pointer;

  &:hover,
  &:active,
  &:focus {
    color: $mack-highlight;
    text-decoration: none;
  }

  &.thumbnail.active,
  &.thumbnail:focus,
  &.thumbnail:hover {
    border-color: $mack-headline-color;
  }
}

/** define overwrites here because of cascade */
#content {
  .google-places {
    .location-sidenav {
      a.tablink {
        text-decoration: none !important;
        color: $mack-headline-color;

        &:hover {
          background: $gray-light;
          color: white;
        }

        &.active, &.active:hover {
          background: $mack-highlight;
          color: white;
        }

      }
    }
  }

  .html-module.chalkboard {
    background: $mack-board-black;
    background-size: 100%;
    padding: 0;
    text-align: center;

    .container {
      @include box-shadow(0 0 60px 60px $mack-board-black inset);
      padding: 90px 0;
      background: $mack-background-board-black-dark;
      background-size: cover;
    }

    a:not(.btn),
    strong,
    b {
      color: $mack-highlight;
    }

    p {
      margin: 0 $mack-font-size-base;
    }

    h1, h2, h3, p {
      color: #fff;
      &:after {
        display: none;
      }
    }

    h1, h2, h3 {
      margin: 0 $mack-font-size-large $mack-font-size-large;
      font-family: $mack-font-highlight;
      font-size: $mack-font-size-large;
      font-weight: normal;
      text-transform: none;
    }
  }
}

strong, b {
  color: $mack-headline-color;
}

.btn:focus {
  color: $mack-headline-color;
  text-decoration: none;
}

legend {
  font-size: 18px;
  padding: 7px 0;
}
label {
  font-size: $mack-font-size-x-small;
  font-weight: normal;
}

.row {
  margin: 0;
}

.row.container {
  margin: auto;
}

.container {
  min-width: 320px;
}

@media (min-width: 1200px) {
  .container:not([class*="col-"]) {
    width: $mack-max-width;
  }
}

@media (min-width: 992px) {
  .container {
    width: 750px;
  }
}

.html-module img {
  max-width: 100%;
}

.img-right {
  float: right;
  margin-left: 30px;
  width: 50%;
  max-width: 50% !important;
}

.headline-extra {
  text-align: center;
  font-size: $mack-font-size-medium;
  text-transform: uppercase;
  font-weight: bold;

  &:after {
    content:"";
    background: $mack-base-color;
    height: 1px;
    width: 150px;
    display: block;
    margin: 10px auto 50px;
  }
}

.panel {
  margin-top: $mack-font-size-large;
  margin-bottom: $mack-font-size-large;
  border-radius: $mack-frame-border-radius !important;

  .panel-heading {
    background: none;

    .panel-title {
      & > a.accordion-toggle {
        width: 100%;
        display: inline-block;
      }
    }
  }

  .panel-heading,
  .panel-footer {
    cursor: pointer;
  }

  &.active {
    .panel-title > a.accordion-toggle {
      color: $mack-highlight !important;
    }
  }
}

.html-module,
.feature-module,
.google-places {
  padding-top: 40px;
  padding-bottom: 40px;
  border-bottom: 1px solid $mack-button-grey;
  position: relative;

  &:first-of-type {
    padding-top: 80px;
  }

  &:last-of-type {
    border-bottom: none;
  }

  .anchor {
    position: absolute;
    top: 0;
  }

  h1, h2, h3 {
    @extend .headline-extra;
  }

}

.unveil {
  opacity: 0;
  @include transition(opacity .3s ease-in);
}

.vat-info, .result-info {
  padding: 10px 0;
}

@media (max-width: 767px) {
  .html-module,
  .feature-module,
  .google-places {
    padding-top: 20px;
    padding-bottom: 20px;
  }
}