.order-info {
  .cell-product-name {
    // overwrite bootstrap directive
    white-space: initial !important;
    word-break: break-word;
  }

  .btn {
    margin: 0;
  }
}

@media screen and (max-width: 767px) {
  .table-responsive.order-info {
    border:none;
  }
}
