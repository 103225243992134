$quantity-input-height: 42px;

.checkout-cart {
  .cart-product {
    padding: $mack-font-size-base 0;
    border-top: 1px solid lightgray;
    position: relative;

    &:last-of-type {
      border-bottom: 1px solid lightgray;
      margin-bottom: $mack-font-size-base;
    }

    .col-xs-3, .col-sm-2  {
      padding-left: 0;
      padding-right: 0;
    }
  }

  .product-thumb {
    min-width: 120px;
  }

  .product-image {
    width: 100%;

    img {
      margin: 0 auto;
    }
  }

  .product-details {
    display: inline-block;
    -webkit-hyphens: auto;
    -moz-hyphens: auto;
    hyphens: auto;

    &.col-sm-10 {
      width: 70%;
    }

    .input-group {
      width: 114px;
    }

    .input-group-btn {
      height: $quantity-input-height;
      vertical-align: top;

      .btn {
        height: $quantity-input-height;
        width: $quantity-input-height;
        margin-left: 0;
      }
    }

    .form-control {
      padding-right: 12px;
      height: $quantity-input-height;
      width: $quantity-input-height * 2;
      text-align: center;
    }

    .price {
      margin-bottom: 18px;
    }
  }

  .product-total {
    float: right !important;
    position:relative;
    margin-top: 22px;
    padding-right: 8px;
  }

  .remove-product, .remove-product:active {
    position: absolute;
    top: 10px;
    right: 0;
    color: $mack-anthrazit;
    background: none;
    border: none;
    font-size: $mack-font-size-base * 1.25;
    @include box-shadow(none);
  }

  .coupon-input {
    .btn {
      height: 34px;
      border-bottom-right-radius: $mack-form-border-radius !important;
      border-top-right-radius: $mack-form-border-radius !important;

    }

    .remove-coupon {
      background: none;
      color: $mack-anthrazit;

      .fa {
        font-size: $mack-font-size-base * 1.25;
      }
    }
  }

  .buttons {
    .button-checkout {
      text-transform: uppercase;
    }
  }

  table.prices {
    > tbody > tr > td {
      font-weight: normal;
      vertical-align: top;
    }

    > tbody > tr.sum > td {
      font-weight: bold;
      font-size: 2rem;
    }
  }
}

@media (max-width: 767px) {
  #collapse-checkout-option {
    div.col-sm-6 {
      margin-top: 25px;

      &:first-of-type {
        margin-top: 0;
      }
    }
  }
}

@media (max-width: 450px) {
  .checkout-cart {

    .cart-product {
      border-top: none !important;
      border-bottom: none !important;
      overflow: hidden;
    }

    .product-thumb {
      min-width: inherit;
      margin-bottom: 5px;
    }

    .product-details {
      &.col-sm-10 {
        width: 75%;
      }
      padding-right: 30px;

      &.col-xs-6 {
        width: 100%;
        padding: 5px 0;
        //@include box-shadow(0px 0px 10px -7px #000);
        @include box-shadow(0px 0px 10px rgba(0, 0, 0, 0.2));
      }

      .control-label {
        display: none;
      }
    }

    .product-total {
      position:absolute;
      bottom: 22px;
      right: 0;
    }
  }
}