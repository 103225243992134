/* menu */
#menu {
  border-radius: 0;
  border: none;
  border-bottom: 1px solid $mack-grey-light;
  background: white;
  //background-image: $mack-background-board-black-divider-dark;
  //background-position: center;
  //background-repeat: no-repeat;
  z-index: 100 !important;
  width: 100%;

  .navbar-header {
    margin: 0;
  }

  .nav > li > a {
    font-size: $mack-font-size-base;
    padding: 0 18px;
    line-height: $mack-header-height;
    display: block;
    background-color: transparent;
    color: $mack-anthrazit;
    font-weight: normal;
    text-decoration: none;
    text-transform: uppercase;
    &.active,
    &:active {
      color: $mack-highlight;
      text-decoration: underline;
    }
  }

  .nav > li > a:focus,
  #menu .nav > li.open > a:focus {
    outline: none;
  }

  .nav > li > a:hover,
  .nav > li > a:focus,
  #menu .nav > li.open > a,
  .nav > li > a.active,
  #menu .nav > li.open > a.active {
    background: $mack-underline2;
    background-size: 80px;
    background-repeat: no-repeat;
    background-position: center 80%;
    text-decoration: none;
  }

  .dropdown-menu {
    padding-bottom: 0;
  }

  .dropdown-inner {
    display: table;

    ul {
      display: table-cell;
    }

    a {
      min-width: 160px;
      display: block;
      padding: 3px 20px;
      clear: both;
      line-height: 20px;
      font-size: $mack-font-size-small;

      &.active {
        text-decoration: underline;
      }

    }
  }

  .see-all {
    display: block;
    margin-top: 0.5em;
    border-top: 1px solid #DDD;
    padding: 3px 20px;
    font-size: $mack-font-size-small;

    &:hover,
    &:focus {
      text-decoration: none;
    }
  }

  #category {
    float: left;
    padding-left: 15px;
    font-size: $mack-font-size-base;
    font-weight: 700;
    line-height: 40px;
  }

  a.btn-navbar {
    @include flexbox();
    @include justify-content(center);
    @include flex-direction(column);
  }

  .btn-navbar {
    @include box-shadow(none);
    background-color: white;
    //background-image: $mack-menu-button;
    border: 1px solid white;
    font-size: 22px !important;
    line-height: 100%;
    margin: 5px 15px;
    padding: 0 10px;
    display: block;
    width: 40px;
    height: 40px;
    font-stretch: expanded;
    color: $mack-anthrazit;

    &:hover {
      opacity: .7;
    }
  }

  &.sticky {
    position: fixed;
  }
}

@media (min-width: 768px) {
  #menu .dropdown:hover .dropdown-menu {
    display: block;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  #menu .nav > li > a {
    padding: 0 10px;
  }
}

@media (max-width: 812px) and (orientation:landscape) {
  #menu {
    .navbar {
      min-height: auto;
    }
  }
}

@media (max-width: 767px) {
  #menu {
    @include box-shadow(0px 5px 10px -10px #000);
    background-image: none;
    background: white;

    .nav {
      margin: 10px auto;
    }

    .nav > li > a {
      line-height: 40px;
    }

    .navbar-collapse {
      //background: $mack-board-black;
      //background-image: $mack-background-board-mobile-dark;
      background-size: cover;
      background-repeat: no-repeat;
      border-top: none;
    }

    div.dropdown-inner > ul.list-unstyled {
      display: block;
    }

    div.dropdown-menu {
      margin-left: 0 !important;
      padding-bottom: 10px;
    }

    .dropdown-inner {
      display: block;
      a {
        width: 100%;
      }
    }

    .see-all {
      margin-top: 0;
      border: none;
      border-radius: 0;
    }
  }
}