/* breadcrumb */
.breadcrumb {
  background: none;
  padding: 20px 0;
  margin-left: 15px;

  .container & {
    margin: 0;
  }
}
.breadcrumb a {
  display: inline-block;
  line-height: 1;
  border-radius: $mack-frame-border-radius;
  text-decoration: underline;
  background: $mack-button-grey;
  padding: 5px 8px;
  font-weight: normal !important;
  color: $mack-link-color !important;
  font-size: $mack-font-size-x-small !important;

  &:hover {
    text-decoration: none;
    color: $mack-headline-color !important;
  }
}

.breadcrumb .separator:last-child {
  display: none;
}

.breadcrumb > li + li::before {
  padding: 0 3px 0 0;
  content: "\f105";
  font-family: FontAwesome;
}